
import { Options, prop, Vue } from 'vue-class-component';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { FormatDate, FormatTime } from '@/helpers/dateHelper';
import { FixtureViewModel } from '@/apiClient';

class FixtureListProps {
    data = prop<FixtureViewModel[]>({ required: true });
    live = prop<boolean>({ default: false });
}

@Options({
    components: {
        FontAwesomeIcon,
    },
})
export default class FixtureList extends Vue.with(FixtureListProps) {
    public date(fixture: FixtureViewModel): string {
        return FormatDate(fixture.date);
    }

    public time(fixture: FixtureViewModel): string {
        return FormatTime(fixture.date);
    }
}
