
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue } from "vue-class-component";

import { FormatDate } from "@/helpers/dateHelper";
import { IPayload } from '@/helpers/promiseHelper';
import { VideoViewModel } from "@/apiClient";

import Image from './Image.vue';
import Spinner from './Spinner.vue';

class VideosProps {
    data: IPayload<VideoViewModel[]> | null = null;
}

@Options({
    components: {
        FontAwesomeIcon,
        Image,
        Spinner
    },
})
export default class Videos extends Vue.with(VideosProps) {
    public date(video: VideoViewModel): string {
        return FormatDate(video.uploadDate);
    }
}
