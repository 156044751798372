import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_FixtureList = _resolveComponent("FixtureList")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", null, [
      _createVNode(_component_FontAwesomeIcon, { icon: "calendar-alt" }),
      _createTextVNode(" Upcoming Fixtures")
    ]),
    _createVNode(_component_Spinner, {
      payload: !_ctx.data
    }, {
      default: _withCtx(() => [
        (_ctx.data?.data)
          ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
              _createVNode(_component_FixtureList, {
                data: _ctx.data.data
              }, null, 8, ["data"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["payload"])
  ], 64))
}