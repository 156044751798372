import { library } from '@fortawesome/fontawesome-svg-core';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCalendarAlt, faFish, faFutbol, faInfoCircle, faNewspaper, faPodcast, faSadTear, faTable, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { createApp } from 'vue';

import App from './App.vue';
import './registerServiceWorker';

library.add(faCalendarAlt, faFish, faFutbol, faInfoCircle, faNewspaper, faPodcast, faSadTear, faTable, faTicketAlt, faYoutube);

createApp(App).mount('#app');
