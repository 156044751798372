import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container mt-lg-4" }
const _hoisted_2 = { class: "py-3 nav nav-pills nav-fill d-lg-none" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Headlines = _resolveComponent("Headlines")!
  const _component_LeagueTable = _resolveComponent("LeagueTable")!
  const _component_UpcomingFixtures = _resolveComponent("UpcomingFixtures")!
  const _component_TodaysFixtures = _resolveComponent("TodaysFixtures")!
  const _component_TheFishyForumThreads = _resolveComponent("TheFishyForumThreads")!
  const _component_NextMatchRemainingSeats = _resolveComponent("NextMatchRemainingSeats")!
  const _component_Videos = _resolveComponent("Videos")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", {
      class: _normalizeClass(["wrapper", { 'mb-5': _ctx.selectedTab !== _ctx.Tab.All }])
    }, [
      _createVNode(_component_Spinner, {
        loading: !_ctx.headlines?.data
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("nav", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getVisibleTabs(), (tab) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: tab,
                  class: "nav-item"
                }, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link", { active: tab === _ctx.selectedTab }]),
                    role: "button",
                    onClick: _withModifiers(($event: any) => (_ctx.selectTab(tab)), ["prevent"])
                  }, _toDisplayString(tab), 11, _hoisted_3)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass({ 'col-lg-8': _ctx.selectedTab === _ctx.Tab.All, 'd-none': _ctx.selectedTab !== _ctx.Tab.All })
              }, [
                _createVNode(_component_Headlines, { data: _ctx.headlines }, null, 8, ["data"])
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass({ 'col-lg-4': _ctx.selectedTab === _ctx.Tab.All, 'col-lg-12': _ctx.selectedTab !== _ctx.Tab.All })
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["mb-4", { 'd-none': !_ctx.tabVisible(_ctx.Tab.LeagueTable) }])
                }, [
                  _createVNode(_component_LeagueTable, { data: _ctx.leagueTable }, null, 8, ["data"])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass({ 'd-none': !_ctx.tabVisible(_ctx.Tab.Fixtures) })
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_UpcomingFixtures, { data: _ctx.upcomingFixtures }, null, 8, ["data"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_TodaysFixtures, { data: _ctx.todaysFixtures }, null, 8, ["data"])
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["mb-4", { 'd-none': !_ctx.tabVisible(_ctx.Tab.TheFishyForumThreads) }])
                }, [
                  _createVNode(_component_TheFishyForumThreads, { data: _ctx.theFishyForumThreads }, null, 8, ["data"])
                ], 2)
              ], 2)
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass({ 'd-none': !_ctx.tabVisible(_ctx.Tab.NextMatchRemainingSeats), 'bg-light py-4': _ctx.selectedTab !== _ctx.Tab.NextMatchRemainingSeats })
          }, [
            _createVNode(_component_NextMatchRemainingSeats, { data: _ctx.nextMatchRemainingSeatCount }, null, 8, ["data"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass({ 'd-none': !_ctx.tabVisible(_ctx.Tab.Videos), 'bg-dark text-light py-5': _ctx.selectedTab !== _ctx.Tab.Videos })
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Videos, { data: _ctx.videos }, null, 8, ["data"])
            ])
          ], 2)
        ]),
        _: 1
      }, 8, ["loading"])
    ], 2),
    _createVNode(_component_Footer)
  ], 64))
}