
import { prop, Vue } from 'vue-class-component';

class ImageProps {
    src = prop<string>({ required: true });
    alt = prop<string>({ required: true });
    fallback = prop<string>({ required: false });
}

export default class Image extends Vue.with(ImageProps) {}
