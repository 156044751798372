
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue } from "vue-class-component";

import { FormatDate } from "@/helpers/dateHelper";
import { IPayload } from '@/helpers/promiseHelper';
import { HeadlineViewModel } from '@/apiClient';

import Image from './Image.vue';
import Spinner from './Spinner.vue';

class HeadlinesProps {
    data: IPayload<HeadlineViewModel[]> | null = null;
}

@Options({
  components: {
    FontAwesomeIcon,  
    Image,
    Spinner
  },
})
export default class Headlines extends Vue.with(HeadlinesProps) {
    public date(headline: HeadlineViewModel): string {
        return FormatDate(headline.date);
    }
}
