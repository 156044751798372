
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue } from "vue-class-component";

import { IPayload } from '@/helpers/promiseHelper';
import { MatchSeatCountViewModel } from "@/apiClient";

import Spinner from './Spinner.vue';

class NextMatchRemainingSeatsProps {
    public data: IPayload<MatchSeatCountViewModel> | null = null;
}

@Options({
    components: {
        FontAwesomeIcon,
        Spinner
    },
})
export default class NextMatchRemainingSeats extends Vue.with(NextMatchRemainingSeatsProps) {
}
