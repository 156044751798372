
import { Options, Vue } from "vue-class-component";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { IPayload } from "@/helpers/promiseHelper";
import { FixtureViewModel } from "@/apiClient";

import FixtureList from './FixtureList.vue';
import Spinner from './Spinner.vue';

class TodaysFixturesProps {
    data: IPayload<FixtureViewModel[]> | null = null;
}

@Options({
    components: {
        FontAwesomeIcon,
        FixtureList,
        Spinner
    }
})
export default class TodaysFixtures extends Vue.with(TodaysFixturesProps) {}
