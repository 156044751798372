import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-015fb556"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "row gy-3"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "h3" }
const _hoisted_5 = { class: "mb-1" }
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_Image = _resolveComponent("Image")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, [
      _createVNode(_component_FontAwesomeIcon, { icon: "newspaper" }),
      _createTextVNode(" Latest headlines")
    ]),
    _createVNode(_component_Spinner, { payload: _ctx.data }, {
      default: _withCtx(() => [
        (_ctx.data?.data)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.data, (headline) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: headline.id,
                  class: "col-lg-4"
                }, [
                  _createElementVNode("a", {
                    href: headline.url,
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }, [
                    _createVNode(_component_Image, {
                      class: "mb-3",
                      src: headline.image,
                      alt: headline.title,
                      fallback: headline.source?.name
                    }, null, 8, ["src", "alt", "fallback"]),
                    _createElementVNode("h1", _hoisted_4, _toDisplayString(headline.title), 1)
                  ], 8, _hoisted_3),
                  _createElementVNode("div", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.date(headline)) + " - ", 1),
                    _createElementVNode("strong", null, [
                      _createElementVNode("a", {
                        href: headline.source.url,
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }, _toDisplayString(headline.source?.name), 9, _hoisted_6)
                    ])
                  ]),
                  _createElementVNode("p", null, _toDisplayString(headline.description), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["payload"])
  ], 64))
}