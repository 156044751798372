export interface IPayload<T> {
    data: T | null;
    error: boolean;
}

export const HandlePromise = <T>(result: PromiseSettledResult<T>): IPayload<T> => {
    if (result.status !== 'fulfilled') {
        return { data: null, error: true };
    }

    return { data: result.value, error: false };
}