import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-544c0c6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "row gy-3"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "d-block mt-3 mb-2" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "h3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_Image = _resolveComponent("Image")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h1", _hoisted_1, [
      _createVNode(_component_FontAwesomeIcon, { icon: ['fab', 'youtube'] }),
      _createTextVNode(" Videos")
    ]),
    _createVNode(_component_Spinner, { payload: _ctx.data }, {
      default: _withCtx(() => [
        (_ctx.data?.data)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.data, (video) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: video.url!,
                  class: "col-lg-3"
                }, [
                  _createElementVNode("a", {
                    href: video.url!,
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }, [
                    _createVNode(_component_Image, {
                      src: video.image,
                      alt: video.title
                    }, null, 8, ["src", "alt"])
                  ], 8, _hoisted_3),
                  _createElementVNode("strong", _hoisted_4, _toDisplayString(video.source.name), 1),
                  _createElementVNode("a", {
                    href: video.url!,
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }, [
                    _createElementVNode("h1", _hoisted_6, _toDisplayString(video.title), 1)
                  ], 8, _hoisted_5),
                  _createElementVNode("strong", null, _toDisplayString(_ctx.date(video)), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["payload"])
  ]))
}