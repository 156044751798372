import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0966b0be"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["image", { 'fallback': !_ctx.src }])
  }, [
    (_ctx.src)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.src,
          alt: _ctx.alt,
          lazy: ""
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.fallback || _ctx.alt), 1))
  ], 2))
}