
import { Options, Vue } from "vue-class-component";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { FixtureViewModel } from "@/apiClient";
import { IPayload } from "@/helpers/promiseHelper";

import FixtureList from './FixtureList.vue';
import Spinner from "./Spinner.vue";

class UpcomingFixturesProps {
    data: IPayload<FixtureViewModel[]> | null = null;
}

@Options({
    components: {
        FontAwesomeIcon,
        FixtureList,
        Spinner
    }
})
export default class UpcomingFixtures extends Vue.with(UpcomingFixturesProps) {}
