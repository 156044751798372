
import { IPayload } from '@/helpers/promiseHelper';
import { Vue } from 'vue-class-component';

class SpinnerProps {
    loading: boolean | null = null;
    payload: IPayload<unknown> | null = null;
}

export default class Spinner extends Vue.with(SpinnerProps) { }
